<script>

	import { createEventDispatcher } from 'svelte';
	import { fly } from 'svelte/transition';
	import moment from 'moment'
	import localization from 'moment/locale/fr'
	// const moment = require( 'moment' )
	// const localization = require( 'moment/locale/fr' )

	export let ref = 'ref'
	export let libelle = 'libelle'
	export let prix = 'prix'
	export let arrivee = 'arrivee'
	export let depart = 'depart'
	export let jours = 'jours'
	export let repartition = 'repartition'

	export let row = {}
	export let index = 0

	let dispatch = createEventDispatcher()

	moment.locale( 'fr', localization );

	function handleClick() {
		// console.log('handleClick')
		dispatch( 'reservation', { row } )
	}

</script>

<style>
	li {
		padding:1px 0 0 10px;
		margin:30px 0 10px 0;
		cursor:pointer;
		color:#fff;
		transition:all 0.2s;
		border-left: 3px solid rgba(255,255,255,0.1);
	}
	li:hover {
		border-color:var(--orange);
	}
	h3 {
		display:block;
		width:100%;
		border-bottom:1px solid rgba(255,255,255,0.1);
		padding:0 0 5px;
		margin: 5px 0;
		transition:all 0.2s;
		font-weight:normal;
		font-size:25px;
		color:var(--orange);
	}
	h3:after {
		content:'';
	}
	.dates {
		display:block;
		width:100%;
		opacity:0.5;
		transition:all 0.2s;
	}
	li:hover .dates {
		opacity:1;
	}
	.prix {
		display:block;
		width:100%;
		text-align:right;
		margin-top:5px;
		font-size:14px;
	}
</style>

<li class="row" on:click={handleClick} data-ref="{ref}" in:fly="{{delay:(index+5)*100,duration:200,y:50}}">
	<h3 class="libelle">{libelle}</h3>
	<div class="dates">
		du {moment( arrivee ).format('LLLL')}
		au {moment( depart ).format('LLLL')}
	</div>
	<div class="prix">{prix} €</div>
</li>
