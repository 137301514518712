<script>

	import { onMount } from 'svelte'
	import Reponse from './Reponse.svelte'

	export let rows = []

	onMount( function() {
		// rows = []
	} )

</script>

<style>
	ul {
		list-style:none;
		margin:0;
		padding:0;
	}
	p {
		text-align:center;
		margin-top:30px;
		line-height: 1.8em;
	}
</style>

{#if rows.length > 0}
	{#if rows.length == 1}
	<p>1 réponse semble convenir à votre demande</p>
	{:else}
	<p>{rows.length} réponses semblent convenir à votre demande</p>
	{/if}
	<ul>
	{#each rows as row, index}
		<Reponse {...row} {row} {index} on:reservation />
	{/each}
	</ul>

{:else}
	<p>Désolé, mais aucune réponse ne semble convenir à vos critères. Vous pouvez les modifier pour vérifier nos disponibilités pour une autre période.</p>
{/if}
