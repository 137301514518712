<script>

	import { createEventDispatcher } from 'svelte'
	import { onMount, onDestroy } from 'svelte'
	import { fade, fly } from 'svelte/transition'
	import TinyDatePicker from 'tiny-date-picker'
	import RechercheDuree from './RechercheDuree.svelte'
	import RechercheNaissances from './RechercheNaissances.svelte'
	import { TinyDatePickerOptions, dateToFr } from './tools'

	export let api = ''
	export let arrivee = dateToFr()
	export let code = ''
	export let nb = ''
	export let naissances = ''
	export let value = {}

	const dispatch = createEventDispatcher()
	let loading = false
	let rows = []
	let url = api + 'wp-json/api/resa/dispos'
	let arriveeDatePicker = null
	let tdp = null
	let message = ''

	$: value = { arrivee, code, nb, naissances }

	// $: console.log( 'Recherche', value )

	onMount( function() {
		tdp = TinyDatePicker( '.arrivee-date-picker', TinyDatePickerOptions )
		tdp.on('select', function( e, dp ) {
			arrivee = dateToFr( dp.state.selectedDate )
		} )
		// dispatch( 'change', recherche )
	} )

	onDestroy( function() {
		tdp.destroy()
	} )

	function rechercher() {
		const el = this
		const params = { arrivee, code, nb, naissances }
		const opt = {
			method: 'POST',
			body: JSON.stringify( params ),
			headers: new Headers( {
				'Content-Type': 'application/json'
			} )
		}
		// console.log(opt.body)
		// dispatch( 'loading' )
		loading = true
		el.disabled = true
		message = ''

		fetch( url, opt )
			.then( function( r ) {
				return r.json()
			} )
			.then( function( r ) {
				// console.log( r )
				if( r.code && r.code === 'SOAP_ERROR' ) {
					message = r.message
				} else {
					rows = r.data
					dispatch( 'loaded', { rows } )
				}
				el.disabled = false
				loading = false
			} )
	}

</script>

<style>
</style>

<label>Vous souhaitez arriver le</label>
<!-- <div class="arrivee-date-picker"></div> -->
<input type="text" class="arrivee-date-picker" bind:value={arrivee} readonly>

<label>Pour une durée de</label>
<RechercheDuree bind:code={code} bind:nb={nb} />

<RechercheNaissances bind:naissances={naissances} />

<div style="text-align:center; margin-top:30px;">
{#if loading}
	<p style="text-align:center;" transition:fade>
		<svg class="lds-glow-ring" style="width:75px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
			<defs>
				<radialGradient id="glow-ring" cx="0.5" cy="0.5" fx="0" fy="0" r="1">
					<stop offset="0%" stop-color="var(--orange)" stop-opacity="0.5"></stop>
					<stop offset="100%" stop-color="var(--orange)" stop-opacity="1"></stop>
				</radialGradient>
			</defs>
			<g>
				<circle cx="50" cy="50" r="30" stroke="var(--bleu)" stroke-width="15" fill="none" stroke-opacity="1"></circle>
				<circle cx="50" cy="50" r="30" stroke="url(#glow-ring)" stroke-width="15" fill="none"></circle>
				<animateTransform attributeName="transform" type="rotate" values="0 50 50;360 50 50" times="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
			</g>
		</svg>
	</p>
{:else}
	<button class="bouton encadre inverse" style="color:#d19813; background:#d19813; width:auto;" on:click={rechercher} in:fade={{delay:400,duration:0}}>
		<span>Rechercher</span>
	</button>
	{#if message != ''}
	<p style="text-align:center;">Une erreur est survenue : {message}</p>
	{/if}
{/if}
</div>
