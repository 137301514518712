const listePays = {
	AF: 'Afghanistan',
	AL: 'Albanie',
	DZ: 'Algérie',
	AS: 'Samoa orientales',
	AD: 'Andorre',
	AO: 'Angola',
	AI: 'Anguilla',
	AQ: 'Antarctique',
	AG: 'Antigua-et-Barbuda',
	AR: 'Argentine',
	AM: 'Arménie',
	AW: 'Aruba',
	AU: 'Australie',
	AT: 'Autriche',
	AZ: 'Azerbaïdjan',
	BS: 'Bahamas',
	BH: 'Bahreïn',
	BD: 'Bangladesh',
	BB: 'Barbade',
	BY: 'Biélorussie',
	BE: 'Belgique',
	BZ: 'Bélize',
	BJ: 'Bénin',
	BM: 'Bermudes',
	BT: 'Bhoutan',
	BO: 'Bolivie',
	BA: 'Bosnie-Herzégovine',
	BW: 'Botswana',
	BV: 'Bouvet (Île)',
	BR: 'Brésil',
	IO: 'Territoire britannique de l\'Océan Indien',
	BN: 'Bruneï',
	BG: 'Bulgarie',
	BF: 'Burkina Faso',
	BI: 'Burundi',
	KH: 'Cambodge',
	CM: 'Cameroun',
	CA: 'Canada',
	CV: 'Cap Vert',
	KY: 'Caïmans (Îles)',
	CF: 'République Centrafricaine',
	TD: 'Tchad',
	CL: 'Chili',
	CN: 'Chine',
	CX: 'Christmas (Île)',
	CC: 'Cocos / Keeling (Îles)',
	CO: 'Colombie',
	KM: 'Comores',
	CG: 'Congo',
	CD: 'République Démocratique du Congo',
	CK: 'Cook (Îles)',
	CR: 'Costa Rica',
	CI: 'Côte D\'Ivoire',
	HR: 'Croatie',
	CU: 'Cuba',
	CY: 'Chypre',
	CZ: 'République Tchèque',
	DK: 'Danemark',
	DJ: 'Djibouti',
	DM: 'Dominique',
	DO: 'République Dominicaine',
	TP: 'Timor-Oriental',
	EC: 'Equateur',
	EG: 'Egypte',
	SV: 'Salvador',
	GQ: 'Guinée Equatoriale',
	ER: 'Erythrée',
	EE: 'Estonie',
	ET: 'Ethiopie',
	FK: 'Falkland / Malouines (Îles)',
	FO: 'Féroé (Îles)',
	FJ: 'Fiji',
	FI: 'Finlande',
	FR: 'France',
	FX: 'France métropolitaine',
	GF: 'Guyane française',
	PF: 'Polynésie française',
	TF: 'Territoires Antarctiques français',
	GA: 'Gabon',
	GM: 'Gambie',
	GE: 'Géorgie',
	DE: 'Allemagne',
	GH: 'Ghana',
	GI: 'Gibraltar',
	GR: 'Grèce',
	GL: 'Groënland',
	GD: 'Grenade',
	GP: 'Guadeloupe',
	GU: 'Guam',
	GT: 'Guatemala',
	GN: 'Guinée',
	GW: 'Guinée-Bissau',
	GY: 'Guyana',
	HT: 'Haïti',
	HM: 'Territoire des Îles Heard et McDonald',
	VA: 'Vatican',
	HN: 'Honduras',
	HK: 'Hong Kong',
	HU: 'Hongrie',
	IS: 'Islande',
	IN: 'Inde',
	ID: 'Indonésie',
	IR: 'Iran (République islamique d\') ',
	IQ: 'Irak',
	IE: 'Irlande',
	IL: 'Israël',
	IT: 'Italie',
	JM: 'Jamaïque',
	JP: 'Japon',
	JO: 'Jordanie',
	KZ: 'Kazakstan',
	KE: 'Kenya',
	KI: 'Kiribati',
	KP: 'Corée (République populaire démocratique du)',
	KR: 'Corée (République démocratique du)',
	KW: 'Koweït',
	KG: 'Kyrgyzstan',
	LA: 'Laos (République populaire démocratique du)',
	LV: 'Lettonie',
	LB: 'Liban',
	LS: 'Lesotho',
	LR: 'Libéria',
	LY: 'Libye (Jamahiriya Arabe Libyenne)',
	LI: 'Liechtenstein',
	LT: 'Lithuanie',
	LU: 'Luxembourg',
	MO: 'Macau',
	MK: 'Macédoine (ancienne République yougoslave de)',
	MG: 'Madagascar',
	MW: 'Malawi',
	MY: 'Malaysie',
	MV: 'Maldives',
	ML: 'Mali',
	MT: 'Malte',
	MH: 'Marshall (Îles)',
	MQ: 'Martinique',
	MR: 'Mauritanie',
	MU: 'Maurice',
	YT: 'Mayotte',
	MX: 'Mexique',
	FM: 'Micronésie (Etats fédérés de)',
	MD: 'Moldavie',
	MC: 'Monaco',
	MN: 'Mongolie',
	MS: 'Montserrat',
	MA: 'Maroc',
	MZ: 'Mozambique',
	MM: 'Myanmar',
	NA: 'Namibie',
	NR: 'Nauru',
	NP: 'Népal',
	NL: 'Pays-Bas',
	AN: 'Antilles néerlandaises',
	NC: 'Nouvelle-Calédonie',
	NZ: 'Nouvelle-Zélande',
	NI: 'Nicaragua',
	NE: 'Niger',
	NG: 'Nigéria',
	NU: 'Niue',
	NF: 'Norfolk (Île)',
	MP: 'Mariannes (Îles)',
	NO: 'Norvège',
	OM: 'Oman',
	PK: 'Pakistan',
	PW: 'Palau',
	PA: 'Panama',
	PG: 'Papouasie Nouvelle-Guinée',
	PY: 'Paraguay',
	PE: 'Pérou',
	PH: 'Philippines',
	PN: 'Pitcaïrn',
	PL: 'Pologne',
	PT: 'Portugal',
	PR: 'Porto Rico',
	QA: 'Quatar',
	RE: 'Réunion',
	RO: 'Romania',
	RU: 'Russie (Fédération de)',
	RW: 'Rwanda',
	KN: 'Saint Kitts et Nevis',
	LC: 'Sainte Lucie',
	VC: 'Saint Vincent et Grenadines',
	WS: 'Samoa',
	SM: 'San Marin',
	ST: 'Sao Tomé et Principe',
	SA: 'Arabie Séoudite',
	SN: 'Sénégal',
	SC: 'Seychelles',
	SL: 'Sierra Léone',
	SG: 'Singapour',
	SK: 'Slovaquie',
	SI: 'Slovénie',
	SB: 'Salomon (Îles)',
	SO: 'Somalie',
	ZA: 'Afrique du Sud',
	GS: 'Géorgie du Sud et îles Sandwich du Sud',
	ES: 'Espagne',
	LK: 'Sri Lanka',
	SH: 'Ste Hélène',
	PM: 'St. Pierre et Miquelon',
	SD: 'Soudan',
	SR: 'Surinam',
	SJ: 'Svalbard et Jan Mayen (Îles)',
	SZ: 'Swaziland',
	SE: 'Suède',
	CH: 'Suisse',
	SY: 'Syrie (République arabe syrienne)',
	TW: 'Taïwan',
	TJ: 'Tadjikistan',
	TZ: 'Tanzanie',
	TH: 'Thaïlande',
	TG: 'Togo',
	TK: 'Tokelau',
	TO: 'Tonga',
	TT: 'Trinidad et Tobago',
	TN: 'Tunisie',
	TR: 'Turquie',
	TM: 'Turkménistan',
	TC: 'Turks et Caicos (Îles)',
	TV: 'Tuvalu',
	UG: 'Ouganda',
	UA: 'Ukraine',
	AE: 'Emirats Arabes Unis',
	GB: 'Royaume-Uni',
	US: 'Etats-Unis d\'Amérique',
	UM: 'Territoires non incorporés des États-Unis ',
	UY: 'Uruguay',
	UZ: 'Ouzbékistan',
	VU: 'Vanuatu',
	VE: 'Vénézuela',
	VN: 'Vietnam',
	VG: 'Vierges (Îles) - RU',
	VI: 'Vierges (Îles) - EU',
	WF: 'Wallis et Futuna (Îles)',
	EH: 'Sahara Occidental',
	YE: 'Yemen',
	YU: 'Yougoslavie',
	ZM: 'Zambie',
	ZW: 'Zimbabwe',
}

export default listePays
