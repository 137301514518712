
function dateToFr( d ) {
	d = d || new Date()
	return ('0'+d.getDate()).slice(-2)+'/'+('0'+(d.getMonth()+1)).slice(-2)+'/'+d.getFullYear()
}

function dateFromFr( str ) {
	const m = /(\d{2})\/(\d{2})\/(\d{4})/.exec( str )
	return ( m==null ? new Date() : new Date( m[3],m[2]-1,m[1] ) )
}

const TinyDatePickerOptions = {
	lang: {
		days: ['Dim','Lun','Mar','Mer','Jeu','Ven','Sam'],
		months: ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'],
		today: 'Aujourd\'hui',
		clear: 'Effacer',
		close: 'Fermer',
	},
	appendTo: document.querySelector( '#reservation .steps' ),
	mode: 'dp-below',
	format: dateToFr,
	parse: dateFromFr,
}

export {
	TinyDatePickerOptions,
	dateToFr,
	dateFromFr
}
