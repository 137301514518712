<script>

	import { onMount, onDestroy } from 'svelte'
	import { createEventDispatcher } from 'svelte'
	import { TinyDatePickerOptions, dateToFr } from './tools'
	import TinyDatePicker from 'tiny-date-picker'
	// const TinyDatePicker = require('tiny-date-picker')
	export let value = ''

	const dispatch = createEventDispatcher()
	let input = null
	let tdp = null

	onMount( function() {
		tdp = TinyDatePicker( input, TinyDatePickerOptions )
		tdp.on( 'select', function( _, dp ) {
			value = dateToFr( dp.state.selectedDate )
			dispatch( 'change', { value } )
		} )
	} )

	onDestroy( function() {
		tdp.destroy()
	} )

</script>

<style>
</style>

<input type="text" bind:this={input} bind:value={value} class:error={value == ''}>
