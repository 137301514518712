<script>
	import { onMount } from 'svelte'

	export let code = 1
	export let nb = 1
	let values = [
		[1,1,'juste une nuit'],
		[1,2,'2 jours'],
		[1,3,'3 jours'],
		[1,4,'4 jours'],
		[1,5,'5 jours'],
		[1,6,'6 jours'],
		[2,1,'1 semaine'],
		[2,2,'2 semaine'],
		[2,3,'3 semaine'],
		[2,4,'4 semaines'],
	]
	let label = getLabel()
	let ul

	function click() {
		code = this.dataset.code
		nb = this.dataset.nb
		label = getLabel()
		ul.style.display = 'none'
		setTimeout( function() {
			ul.style.display = 'block'
		}, 100 )
	}

	function getLabel() {
		let l = ''
		for( let i=0, j=values.length; i<j; i++ )
			if( values[i][0] == code && values[i][1] == nb )
				l = values[i][2]
		return l
	}


</script>

<style>
	div {
		position:relative;
		border-bottom:1px solid #d19813;
		padding:0;
		cursor:pointer;
	}
	span {
		position:relative;
		display:block;
		background:#142b4a;
		padding:5px 0;
		/* font-weight:bold; */
		z-index:2;
		min-height:24px;
	}
	ul {
		display:block;
		position:absolute;
		list-style:none;
		margin:0;
		padding:5px 0;
		top:-1000px;
		font-size:16px;
		left:0;
		width:100%;
		background:#142b4a;
		z-index:1;
		box-shadow:0 10px 10px rgba(0,0,0,0.3);
		transform:translateY(-20px);
		transition:transform 0.2s;
		max-height:40vh;
		overflow-y:auto;
		overflow-x:hidden;
	}
	li {
		margin:0;
		padding:10px 15px;
	}
	li:hover {
		background:#d19813;
		color:#142b4a;
	}
	div:hover ul {
		display:block;
		top:calc(100% + 1px);
		transform:translateY(0);
	}
	svg {
		display:block;
		position:absolute;
		top:26px;
		right:-15px;
		z-index:3;
		transform:translate(-50%,-50%);
		transition:transform 0.2s;
	}
	div:hover svg {
		transform:translate(-50%,-50%) rotate(-180deg);
	}
</style>

<div class="input">
	<span>{label}</span>
	<svg xmlns="http://www.w3.org/2000/svg" width="28.163" height="9.161" viewBox="0 0 28.163 9.161"><path fill="#DF9A14" d="M14.088 7.137L1.459.24a.879.879 0 0 0-1.207 0 .83.83 0 0 0 0 1.193l13.231 7.475a.833.833 0 0 0 1.194 0l13.231-7.475a.829.829 0 0 0 0-1.193.859.859 0 0 0-1.191 0L14.088 7.137z"/></svg>
	<ul bind:this={ul}>
	{#each values as v}
		<li data-code="{v[0]}" data-nb="{v[1]}" on:click={click}>{v[2]}</li>
	{/each}
	</ul>
</div>
