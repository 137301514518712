<script>

	import { onMount } from 'svelte'
	import { fade, fly } from 'svelte/transition'
	import Recherche from './Recherche.svelte'
	import Reponses from './Reponses.svelte'
	import Reservation from './Reservation.svelte'

	export let api = ''
	export let distance = 50

	const steps = ['recherche','reponses','reservation']
	let step = ''
	let sensIn = -1
	let sensOut = 1
	let loadingClass = ''
	let rows = []
	let row = {}
	let mounted = false
	let recherche
	let resa
	let value

	// $: console.log( 'Steps', value )

	$: if( mounted ) {
		value = { step, recherche, rows, row, resa }
		localStorage.setItem( 'reservation', JSON.stringify( value ) )
	}

	onMount( function() {
		const test = JSON.parse( localStorage.getItem( 'reservation' ) )
		if( test !== null ) {
			// console.log( 'localstorage')
			step = test.step
			recherche = test.recherche
			rows = test.rows
			row = test.row
			resa = test.resa
		} else {
			step = 'recherche'
		}
		setTimeout( function() { goto( step ) }, 100 )
		mounted = true
	} )

	function rechercheLoaded( e ) {
		setTimeout( function() { loadingClass = '' }, 200 )
		rows = e.detail.rows
		goto( 'reponses' )
		// console.log( rows )
	}

	function reservation( e ) {
		row = e.detail.row
		goto( 'reservation' )
		// console.log( 'choix', row )
	}

	function reservationLoaded( e ) {
		document.location.href = e.detail.url
	}

	function goto( str ) {
		const from = step
		const to = ( typeof str == 'string' ? str : str.detail.step ) // appel direct ou depuis un événement
		const indexFrom = steps.indexOf( from )
		const indexTo = steps.indexOf( to )
		sensIn = ( indexTo < indexFrom ? -1 : 1 )
		sensOut = sensIn * -1
		step = to
	}

</script>

<style>
	:global(:root) {
		--bleu:#142b4a;
		--orange:#d19813;
		--color:var(--bleu);
	}
	:global(body) {
		background:#142b4a;
		color:#fff;
		padding:15px;
	}
	:global(.unselectable) {
		-moz-user-select:none;
		-webkit-user-select:none;
		-ms-user-select:none;
		user-select:none;
	}
	:global(input[type="text"],select,.input) {
		background:none;
		padding: 5px 0;
		color: #fff;
		border: none;
		width: 100%;
		font-size:25px;
		border-bottom: 1px solid #d19813;
		text-align: center;
	}
	:global(label) {
		display:block;
		margin:20px 0 10px;
	}
	:global(button) {
		padding: 0.4em;
		margin: 0 0 10px 0;
		box-sizing: border-box;
		border:0;
		display:block;
		width:100%;
		text-align:center;
	}
	:global(.dp-modal) {
		background:rgba(0,0,0,0.5) !important;
		z-index:10;
	}
	:global(.dp-below) {
		max-width:calc(100% - 30px);
	}
	:global(.dp::before) {
		background: #142b4a !important;
	}
	:global(.dp button) {
		margin:0;
		/* padding:0; */
	}
	:global(.dp .dp-cal-header) {
		padding:0;
	}
	:global(.dp header button) {
		width:auto;
	}
	:global(.dp footer button) {
		color:#000;
	}
	:global(.dp .dp-prev, .dp .dp-next) {
		width:30px;
	}
	:global(.dp .dp-prev::before, .dp .dp-next::before) {
		margin:3px 0 40px 4px;
	}
	:global(.dp .dp-next::before) {
		margin:3px 0 40px -4px;
	}
	:global(.error) {
		border-color: #f00 !important;
	}
	.steps {
		overflow:visible;
	}
	.steps .step {
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		transition:opacity 0.3s;
		background-color:var(--bleu);
		padding:15px;
	}
	.loading {
		opacity:0.5;
	}
	.steps button {
		background:none;
		border:0;
		color:var(--orange);
		padding:0;
		transition:opacity 0.2s;
		cursor:pointer;
	}
	.step > .prev, .step > .next {
		padding:10px;
	}
	.prev {
		left:0;
	}
	.next {
		right:0;
	}

</style>


<div class="steps unselectable {loadingClass}">

	{#if step == 'recherche'}
		<div class="step" in:fly={{x:distance * sensIn}} out:fly={{x:distance * sensOut}}>
			<Recherche
				{api}
				bind:value={recherche}
				{...recherche}
				on:loaded={rechercheLoaded}
			/>
		</div>
	{/if}

	{#if step == 'reponses'}
		<div class="step" in:fly={{x:distance * sensIn}} out:fly={{x:distance * sensOut}}>
			<button class="prev" on:click={(e)=>goto('recherche')}>Modifier la recherche</button>
			<Reponses bind:rows={rows} on:reservation={reservation} />
		</div>
	{/if}

	{#if step == 'reservation'}
		<div class="step" in:fly={{x:distance * sensIn}} out:fly={{x:distance * sensOut}}>
			<button class="prev" on:click={(e)=>goto('reponses')}><span></span>Retour au détail de l'offre</button>
			<Reservation
				{api}
				{row}
				{recherche}
				bind:value={resa}
				{...resa}
				on:loaded={reservationLoaded}
			/>
		</div>
	{/if}

</div>
