<script>

	import { onMount } from 'svelte'
	import RechercheNaissancesInput from './RechercheNaissancesInput.svelte'
	import { fade, fly } from 'svelte/transition'

	export let naissances = ''
	// $: console.log( naissances )

	const max = 9
	let liste = ['']
	let ul = null
	let mounted = false

	$: nb = liste.length
	$: if( mounted ) naissances = liste.join( '-' )

	onMount( function() {
		if( naissances != '' )
			liste = [...naissances.split( '-' )]
		mounted = true
	} )

	function get() {
		return liste.join( '-' )
	}

	function add() {
		if( nb < max ) {
			liste.push( '' )
			liste = liste
		}
	}

	function del() {
		if( nb > 1 ) {
			liste.pop()
			liste = liste
		}
	}

	function valueChange( e ) {
		const inputs = ul.querySelectorAll( 'input' )
		const newListe = []
		inputs.forEach( function( el, i ) {
			newListe[i] = el.value
		} )
		liste = [...newListe]
	}

</script>

<style>
	.disabled {
		opacity:0.5;
		pointer-events:none;
	}
	label {
		display:block;
		margin:20px 0 10px;
	}
	span {
		display: inline-block;
		font-size: 25px;
		vertical-align: middle;
		border-radius: 50%;
		background: var(--orange);
		width: 38px;
		height: 38px;
		text-align: center;
	}
	ul {
		list-style:none;
		margin:0;
		padding:0;
	}
	li {
		position:relative;
	}
	ul span {
		position:absolute;
		top:5px;
		left:5px;
	}
	button {
		color: var(--orange);
		font-size: 50px;
		line-height: 30px;
		width: 48px;
		display: inline-block;
		text-align: center;
		cursor: pointer;
		margin:0;
		padding:0;
		background:none;
		border:0;
		vertical-align:middle;
	}
	button:hover {
		color: #fff;
	}
</style>

<label on:click|preventDefault>
	Nombre de participants
</label>
<div style="text-align:center;">
	<button on:click ={del} class:disabled="{nb <= 1}"> - </button>
	<span>{nb}</span>
	<button on:click={add} class:disabled="{nb >= max}"> + </button>
</div>

{#if nb > 0}
	<label>
	{#if nb == 1}
		Veuillez indiquer votre date de naissance
	{:else}
		Veuillez indiquer la date de naissance des {nb} participants
	{/if}
	</label>

	<ul bind:this={ul}>
	{#each liste as value, i}
		<li transition:fly={{duration:200}}><span>{i+1}</span><RechercheNaissancesInput {value} on:change={valueChange}/></li>
	{/each}
	</ul>
{/if}
