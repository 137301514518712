// import '../node_modules/tiny-date-picker/tiny-date-picker.css'
import Steps from './Steps.svelte';

const steps = new Steps( {
	target: document.querySelector( '.steps' ),
	props: {
		api: 'http://srv-web/port-aux-rocs/www/'
	}
} )

export default steps
