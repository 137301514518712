<script>

	import { createEventDispatcher } from 'svelte'
	import { fade, fly } from 'svelte/transition'
	import listePays from './pays.js'

	export let api = ''
	export let row = {}
	export let recherche = {}
	export let value = {}

	export let civilite = 'Mme'
	export let nom = ''
	export let prenom = ''
	export let adresse1 = ''
	export let adresse2 = ''
	export let cp = ''
	export let ville = ''
	export let pays = 'FR'
	export let tel1 = ''
	export let fax = ''
	export let email = ''
	export let commentaire = ''
	export let newsletter = false
	export let naissances = ''

	const dispatch = createEventDispatcher()
	let loading = false
	let url = api + 'wp-json/api/resa/preresa'
	let message = ''

	$: labelPays = listePays[pays]
	$: value = {
		ref: row.ref,
		debut: row.arrivee,
		fin: row.depart,
		naissances: recherche.naissances,
		civilite,nom,prenom,
		adresse1,adresse2,cp,ville,pays,
		tel1,email,
		commentaire,newsletter,
	}

	let ul
	// $: console.log( data )

	function selectPays() {
		pays = this.dataset.value
		ul.style.display = 'none'
		setTimeout( function() {
			ul.style.display = 'block'
		}, 100 )
	}

	function resapre() {
		const el = this
		const opt = {
			method: 'POST',
			body: JSON.stringify( value ),
			headers: new Headers( {
				'Content-Type': 'application/json'
			} )
		}
		// console.log(opt.body)
		loading = true
		el.disabled = true
		message = ''

		fetch( url, opt )
			.then( function( r ) {
				return r.json()
			} )
			.then( function( r ) {
				// console.log( r )
				if( r.code && r.code === 'SOAP_ERROR' ) {
					message = r.message
					el.disabled = false
					loading = false
				} else {
					// console.log( r )
					dispatch( 'loaded', { url: r.data.url } )
				}
			} )
			.catch( function() {
				message = 'Impossible de lire la réponse du serveur'
			} )
	}

	function getLabel() {
		let l = ''
		for( let i=0, j=values.length; i<j; i++ ) {
			if( values[i][0] == code && values[i][1] == nb ) {
				l = values[i][2]
			}
		}
		return l
	}

</script>

<style>
	label {
		display:inline-block;
		margin:2px 0 0;
		font-size:20px;
	}
	input {
		text-align:left;
		font-size:20px;
	}
	input[type="radio"] + span {
		font-size:20px;
		transition: all 0.2s;
		border-bottom:1px solid var(--orange);
	}
	input[type="radio"] + span span {
		opacity:0.5;
	}
	input[type="radio"]:checked + span {
		background:var(--orange);
	}
	input[type="radio"]:checked + span span {
		opacity:1;
	}


	.select {
		position:relative;
		border-bottom:1px solid #d19813;
		padding:0;
		cursor:pointer;
	}
	.select span {
		position:relative;
		display:block;
		background:#142b4a;
		padding:5px 0;
		min-height:24px;
		text-align:left;
		font-size:20px;
	}
	.select ul {
		display:block;
		position:absolute;
		list-style:none;
		margin:0;
		padding:5px 0;
		top:-10000px;
		font-size:16px;
		left:0;
		width:100%;
		background:#142b4a;
		z-index:1;
		box-shadow:0 10px 10px rgba(0,0,0,0.3);
		transform:translateY(-20px);
		transition:transform 0.2s;
		max-height:40vh;
		overflow-y:auto;
		overflow-x:hidden;
	}
	.select li {
		margin:0;
		padding:6px 12px;
		text-align:left;
	}
	.select li:hover {
		background:#d19813;
		color:#142b4a;
	}
	.select:hover ul {
		display:block;
		top:calc(100% + 1px);
		transform:translateY(0);
	}
	.select svg {
		display:block;
		position:absolute;
		top:22px;
		right:-15px;
		/* z-index:3; */
		transform:translate(-50%,-50%);
		transition:transform 0.2s;
	}
	.select:hover svg {
		transform:translate(-50%,-50%) rotate(-180deg);
	}

</style>

<p style="margin-top:30px;">
	Cette offre vous intéresse ?
	Afin de la réserver, merci de nous fournir les informations suivantes.
</p>


<div class="row">

	<div class="col-12">
		<h3 class="mt-3">Vous</h3>
	</div>
	<div class="col-12 col-sm-3">
		<div class="row text-center">
			<label class="col-6 pr-0">
				<input type="radio" bind:group={civilite} value="Mme" class="d-none">
				<span class="d-block p-1"><span>Mme</span></span>
			</label>
			<label class="col-6 pl-0">
				<input type="radio" bind:group={civilite} value="M." class="d-none">
				<span class="d-block p-1"><span>M.</span></span>
			</label>
		</div>
	</div>
	<div class="col-12 col-sm-4">
		<input type="text" placeholder="prénom" bind:value={prenom}>
	</div>
	<div class="col-12 col-sm-5">
		<input type="text" placeholder="nom" bind:value={nom}>
	</div>

	<div class="col-12">
		<h3 class="mt-3">Votre adresse</h3>
	</div>
	<div class="col-12">
		<input type="text" placeholder="adresse 1" bind:value={adresse1}>
	</div>
	<div class="col-12">
		<input type="text" placeholder="adresse 2" bind:value={adresse2}>
	</div>
	<div class="col-5 col-sm-2">
		<input type="text" placeholder="cp" bind:value={cp} maxlength="5">
	</div>
	<div class="col-7 col-sm-5">
		<input type="text" placeholder="ville" bind:value={ville}>
	</div>
	<div class="col-12 col-sm-5">
		<div class="input select">
			<span>{labelPays}</span>
			<svg xmlns="http://www.w3.org/2000/svg" width="28.163" height="9.161" viewBox="0 0 28.163 9.161"><path fill="#DF9A14" d="M14.088 7.137L1.459.24a.879.879 0 0 0-1.207 0 .83.83 0 0 0 0 1.193l13.231 7.475a.833.833 0 0 0 1.194 0l13.231-7.475a.829.829 0 0 0 0-1.193.859.859 0 0 0-1.191 0L14.088 7.137z"/></svg>
			<ul bind:this={ul}>
			{#each Object.entries( listePays ) as [k, v]}
				<li data-value={k} on:click={selectPays}>{v}</li>
			{/each}
			</ul>
		</div>
	</div>

	<div class="col-12">
		<h3 class="mt-3">Pour vous contacter</h3>
	</div>
	<div class="col-12 col-sm-6">
		<input type="text" placeholder="téléphone" bind:value={tel1}>
	</div>
	<div class="col-12 col-sm-6">
		<input type="text" placeholder="e-mail" bind:value={email}>
	</div>
	<div class="col-12">
		<label>
			<input type="checkbox" bind:checked={newsletter}>
			inscription à la newsletter
		</label>
	</div>
	<div class="col-12">
		<input type="text" placeholder="commentaire" bind:value={commentaire}>
	</div>
</div>


<div style="text-align:center; margin-top:30px;">
{#if loading}
	<p style="text-align:center;" transition:fade>
		<svg class="lds-glow-ring" style="width:75px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
			<defs>
				<radialGradient id="glow-ring" cx="0.5" cy="0.5" fx="0" fy="0" r="1">
					<stop offset="0%" stop-color="var(--orange)" stop-opacity="0.5"></stop>
					<stop offset="100%" stop-color="var(--orange)" stop-opacity="1"></stop>
				</radialGradient>
			</defs>
			<g>
				<circle cx="50" cy="50" r="30" stroke="var(--bleu)" stroke-width="15" fill="none" stroke-opacity="1"></circle>
				<circle cx="50" cy="50" r="30" stroke="url(#glow-ring)" stroke-width="15" fill="none"></circle>
				<animateTransform attributeName="transform" type="rotate" values="0 50 50;360 50 50" times="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
			</g>
		</svg>
	</p>
{:else}
	<button class="bouton encadre inverse" style="color:#d19813; background:#d19813; width:auto;" on:click={resapre} in:fade={{delay:400,duration:0}}>
		<span>Valider les informations</span>
	</button>
	{#if message != ''}
	<p style="text-align:center;">Une erreur est survenue : {message}</p>
	{/if}
{/if}
</div>
